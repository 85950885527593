// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "25.2.0",
  "buildNumber": "20131",
  "gitSha": "e841de6356d9a474f734b27f3d15730d8c1c0dc3",
  "fullVersion": "25.2.0-20131",
  "formattedVersion": "25.2.0 (20131-e841de6356d9a474f734b27f3d15730d8c1c0dc3)"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/25.2.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/25.2.0/${stripLeadingPrefix(suffix, '#')}`
}
    
